const routes = {
  LOGIN: "/",
  FORGET_PASSWORD: "/forget-password",
  UPDATE_PASSWORD: "/update/password",
  COMPLETE_PROFILE: "/complete-profile",
  DASHBOARD: "/dashboard",
  ACCOUNT_INFO: "account",
  MEETINGS: {
    INDEX: "meetings",
    USER_MEETING: "user-meetings",
    MANAGER_MEETING: "manager-meetings",
    ASSOCIATE_MEETING: "associate-meetings",
    CONFIRMED_MEETING: "confirmed-meetings",
    COMPLETED_MEETING: "completed-meetings",
    CREATE: "create",
    EDIT: (id: string | number = ":id") => `edit/${id}`,
    VIEW: (id: string | number = ":id") => `view/${id}`,
  },
  USERS: {
    INDEX: "employees",
    CREATE: "create",
    EDIT: (id: string | number = ":id") => `edit/${id}`,
    VIEW: (id: string | number = ":id") => `view/${id}`,
  },
  REPORTINGS: {
    INDEX: "reportings",
  },
  ASSOCIATIONS: {
    INDEX: "users",
    CREATE: "create",
    EDIT: (id: string | number = ":id") => `edit/${id}`,
    VIEW: (id: string | number = ":id") => `view/${id}`,
  },
  DEPARTMENT: {
    INDEX: "departments",
    CREATE: "create",
    EDIT: (id: string | number = ":id") => `edit/${id}`,
    VIEW: (id: string | number = ":id") => `view/${id}`,
  },
  ORGANIZATION_UNIT: {
    INDEX: "organizational-units",
    CREATE: "create",
  },
  SUB_DEPARTMENT: {
    INDEX: "sub-departments",
    CREATE: "create",
    EDIT: (id: string | number = ":id") => `edit/${id}`,
    VIEW: (id: string | number = ":id") => `view/${id}`,
  },
  DIVISION: {
    INDEX: "divisions",
    CREATE: "create",
    EDIT: (id: string | number = ":id") => `edit/${id}`,
    VIEW: (id: string | number = ":id") => `view/${id}`,
  },
  SERVICE: {
    INDEX: "services",
    CREATE: "create",
    EDIT: (id: string | number = ":id") => `edit/${id}`,
    VIEW: (id: string | number = ":id") => `view/${id}`,
  },
  Visitors: {
    INDEX: "visitors",
    CREATE: "create",
  },
  MEETING_AGENDA: {
    INDEX: "meeting-agendas",
    CREATE: "create",
  },
  DECISION_FOLLOW_UP: {
    INDEX: "decision-follow-up",
    EDIT: (id: string | number = ":id") => `edit/${id}`,
    VIEW: (id: string | number = ":id") => `view/${id}`,
    CREATE: "create",
  },
} as const;

export default routes;
