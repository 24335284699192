import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

const App: React.FC = () => (
  <Spin
    fullscreen
    indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />}
  />
);

export default App;
