import routes from "@/routes";
import { lazy } from "react";
const User = lazy(() => import("@/modules/User"));
const ListUser = lazy(() => import("@/modules/User/List"));
const CreateUser = lazy(() => import("@/modules/User/Create"));

const MeetingRoutes = {
  element: <User />,
  path: routes.USERS.INDEX,
  children: [
    {
      element: <ListUser />,
      path: "",
    },
    {
      element: <CreateUser />,
      path: routes.USERS.CREATE,
    },
  ],
};

export default MeetingRoutes;
