import { PREVIOUS_USER_KEY, TOKEN_KEY, USER_KEY } from "@/constants";
import { messages } from "@/messages";
import { IAxiosOnErrorResponse } from "@/types";
import axios, { AxiosResponse } from "axios";

const client = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    Authorization: "Bearer " + window.localStorage.getItem(TOKEN_KEY),
  },
});

export function setAuthHeader(token: string) {
  client.defaults.headers["Authorization"] = `Bearer ${token}`;
}

const onSuccess = function (response: AxiosResponse<any, any>) {
  return response.data;
};

const onError = function (error: any): Promise<IAxiosOnErrorResponse> {
  console.error("Request Failed:", error.config);
  if (error.response) {
    // Request was made but server responded with something
    // other than 2xx
    console.error("Status:", error.response.status);
    console.error("Data:", error.response.data);
    console.error("Headers:", error.response.headers);
    if (
      error.response.data.statusCode === 401 &&
      [messages.unauthorized, messages.missingAuthentication].includes(
        error.response.data.message
      )
    ) {
      window.localStorage.removeItem(TOKEN_KEY);
      window.localStorage.removeItem(USER_KEY);
      window.location.reload();
    }
  } else {
    // Something else happened while setting up the request
    // triggered the error
    console.error("Error Message:", error.message);
  }

  return Promise.reject({
    errMsg: !error?.response
      ? "Network Issue!"
      : error?.response?.data?.message ||
        error?.response?.data?.errors[0].param +
          " " +
          error?.response?.data?.errors[0].msg,
    status: error?.response?.status || "not status",
  });
};

client.interceptors.response.use(onSuccess, onError);

export default client;
