import routes from "@/routes";
import { lazy } from "react";
const Service = lazy(() => import("@/modules/Service"));
const ListService = lazy(() => import("@/modules/Service/List"));
const CreateService = lazy(() => import("@/modules/Service/Create"));

const ServiceRoutes = {
  element: <Service />,
  path: routes.SERVICE.INDEX,
  children: [
    {
      element: <CreateService />,
      path: routes.SERVICE.CREATE,
    },
  ],
};

export default ServiceRoutes;
