import routes from "@/routes";
import { lazy } from "react";
const SubDepartment = lazy(() => import("@/modules/SubDepartment"));
const ListSubDepartment = lazy(() => import("@/modules/SubDepartment/List"));
const CreateSubDepartment = lazy(
  () => import("@/modules/SubDepartment/Create")
);

const SubDepartmentRoutes = {
  element: <SubDepartment />,
  path: routes.SUB_DEPARTMENT.INDEX,
  children: [
    {
      element: <CreateSubDepartment />,
      path: routes.SUB_DEPARTMENT.CREATE,
    },
  ],
};

export default SubDepartmentRoutes;
