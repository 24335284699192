import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import ReactDOM from "react-dom/client";

// mui imports

// local custom imports
import Routes from "@/App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { LocaleProvider } from "./context/LocaleContext";
import { MeetingCountProvider } from "./context/SocketContext";
import { UserProvider } from "./context/UserContext";
import "./index.css";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <MeetingCountProvider>
          <LocaleProvider>
            <Routes />
          </LocaleProvider>
        </MeetingCountProvider>
      </UserProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);
