import routes from "@/routes";
import { lazy } from "react";
const EmployeeAssociation = lazy(() => import("@/modules/EmployeeAssociation"));
const ListEmployeeAssociation = lazy(
  () => import("@/modules/EmployeeAssociation/List")
);
const CreateEmployeeAssociation = lazy(
  () => import("@/modules/EmployeeAssociation/Create")
);

const AssociationRoutes = {
  element: <EmployeeAssociation />,
  path: routes.ASSOCIATIONS.INDEX,
  children: [
    {
      element: <ListEmployeeAssociation />,
      path: "",
    },
  ],
};

export default AssociationRoutes;
