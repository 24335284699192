import { setAuthHeader } from "@/apis/client";
import { getUserDetails } from "@/apis/v1/user";

import { TOKEN_KEY } from "@/constants";
import { IAdmin, IUser } from "@/modules/User/user.interface";

import routes from "@/routes";
import { useQuery } from "@tanstack/react-query";
import * as React from "react";

const UserContext = React.createContext<IUserContext | null>(null);

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [token, setToken] = React.useState<string>(
    window.localStorage.getItem(TOKEN_KEY) ?? ""
  );
  // function decodeTokenFN() {
  //   try {
  //     const token = window.localStorage.getItem(TOKEN_KEY) ?? "";
  //     if (!token) return null;
  //     return jwtDecode(token);
  //   } catch (error) {
  //     console.error("Invalid token:", error);
  //     return null;
  //   }
  // }
  // const [decodeToken, setDecodeToken] = React.useState<any>(null);
  // React.useEffect(() => {
  //   setDecodeToken(decodeTokenFN());
  // }, [token]);
  const [userType, setUserType] = React.useState<string>(
    window.localStorage.getItem("userType") ?? ""
  );
  const [user, setUser] = React.useState<null | IUser>(null);
  const [admin, setAdmin] = React.useState<null | IAdmin>(null);
  useQuery<any>(
    user?.id ? ["user", user?.id] : ["admin", admin?.id],
    getUserDetails,
    {
      enabled: Boolean(token),
      onSuccess: (data) => {
        if (data?.isAdmin) {
          setAdmin(data);
          setUser(null);
        } else {
          setUser(data);
          setAdmin(null);
        }
      },
    }
  );
  // useQuery<IAdmin>(["admin", admin?.id], getAdminDetails, {
  //   enabled: Boolean(token && admin?.id),
  //   onSuccess: (data) => {
  //     if (data) {
  //       setAdmin(data);
  //     }
  //   },
  // });

  function logout() {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem("userType");
    setUser(null);
    setAdmin(null);
    setToken("");
    setAuthHeader("");
    window.location.href = routes.LOGIN;
  }

  return (
    <UserContext.Provider
      value={{
        isAuthenticated: Boolean(token),
        user: user as IUser,
        setUser,
        token,
        setToken,
        logout,
        setUserType,
        userType,
        setAdmin,
        admin: admin as IAdmin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext can only be used inside UserProvider");
  }
  return context;
}

interface IUserContext {
  isAuthenticated: boolean;
  user: IUser;
  admin: IAdmin;
  setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
  setAdmin: React.Dispatch<React.SetStateAction<IAdmin | null>>;
  token: string;
  setToken: React.Dispatch<React.SetStateAction<string>>;
  userType: string;
  setUserType: React.Dispatch<React.SetStateAction<string>>;
  logout: () => void;
}
