import appendQueryParams from "@/utils/appendQueryParams";
import { QueryFunctionContext } from "@tanstack/react-query";
import client from "../client";

export function login(payload: any) {
  return client.post("/admin/login", payload);
}
export function getAdminDetails() {
  return client.get("/admin/profile");
}
