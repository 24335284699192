import routes from "@/routes";
import { lazy } from "react";
const Insight = lazy(() => import("@/modules/DecisionFollowUp"));
const ListInsight = lazy(() => import("@/modules/DecisionFollowUp/List"));
const CreateDecisionFollowUp = lazy(
  () => import("@/modules/DecisionFollowUp/Create")
);

const MeetingFollowRoutes = {
  element: <Insight />,
  path: routes.DECISION_FOLLOW_UP.INDEX,
  children: [
    {
      element: <ListInsight />,
      path: "",
    },
    {
      element: <CreateDecisionFollowUp />,
      path: routes.DECISION_FOLLOW_UP.CREATE,
    },
    {
      element: <CreateDecisionFollowUp isEdit={false} isView={true} />,
      path: routes.DECISION_FOLLOW_UP.VIEW(),
    },
    {
      element: <CreateDecisionFollowUp isEdit={true} isView={false} />,
      path: routes.DECISION_FOLLOW_UP.EDIT(),
    },
  ],
};

export default MeetingFollowRoutes;
