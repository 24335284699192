import routes from "@/routes";
import { lazy } from "react";
const Visitor = lazy(() => import("@/modules/MeetingAgendas"));
const ListVisitors = lazy(() => import("@/modules/MeetingAgendas/List"));
const CreateMeetingAgendas = lazy(
  () => import("@/modules/MeetingAgendas/Create")
);

const MeetingAgendaRoutes = {
  element: <Visitor />,
  path: routes.MEETING_AGENDA.INDEX,
  children: [
    {
      element: <ListVisitors />,
      path: "",
    },
    {
      element: <CreateMeetingAgendas />,
      path: routes.MEETING_AGENDA.CREATE,
    },
  ],
};

export default MeetingAgendaRoutes;
