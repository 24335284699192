import appendQueryParams from "@/utils/appendQueryParams";
import { QueryFunctionContext } from "@tanstack/react-query";
import client from "../client";

export function createVisitor(payload: any) {
  return client.post("/meetings/visitors", payload);
}
export function createMeeting(payload: any) {
  return client.post("/meetings", payload);
}
export function updateMeeting({ payload, id }: any) {
  return client.put(`/meetings/${id}`, payload);
}
export function updateMeetingStatus({ id, payload }: any) {
  return client.patch(`/meetings/${id}/status`, payload);
}
export const updateMeetingPriority = async (
  updatedPriorities: { id: number; priority: number }[]
) => {
  await client.put("/meetings/update-priority", updatedPriorities);
};
export function getMeetings(queryParams: any) {
  return client.get(appendQueryParams("/meetings", queryParams ?? {}));
}
export function getMeetingPendingCount() {
  return client.get("/meetings/pendingCounts");
}
export function getFollowUp(queryParams: any) {
  return client.get(
    appendQueryParams("/meetings/with-follow-up", queryParams ?? {})
  );
}
export function getMeetingReportStat() {
  return client.get("/meetings/stats");
}
export function getVisitors() {
  return client.get("/meetings/visitors");
}
export function getVisitorByPhoneNumber(phoneNumber: string) {
  return client.get(`/meetings/visitors/search/${phoneNumber}`);
}
