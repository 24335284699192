import { setAuthHeader } from "@/apis/client";
import { login as adminLogin } from "@/apis/v1/admin";
import { login } from "@/apis/v1/auth";
import { languages, TOKEN_KEY } from "@/constants";
import { useLocaleContext } from "@/context/LocaleContext";
import { useUserContext } from "@/context/UserContext";
import { useLocales } from "@/hooks/useLocales";
import { messages } from "@/messages";
import routes from "@/routes";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Button, Col, Form, Input, notification, Row } from "antd";
import Lottie from "lottie-react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo/long-logo.jpeg";
import animationData from "../../assets/lottie/login-people.json";
import "./index.css";
interface FormData {
  username?: string;
  email?: string;
  password: string;
}

const Login = ({ isAdmin }: any) => {
  const { pathname } = useLocation();
  // const isAdmin = pathname.includes("admin");
  const [api, contextHolder] = notification.useNotification();
  const { t } = useLocales();

  const [form] = Form.useForm();
  notification.config({
    placement: "bottomRight",
    bottom: 50,
    duration: 3,
    rtl: true,
    maxCount: 1,
  });
  const { setToken, setUser, setAdmin } = useUserContext();

  const navigate = useNavigate();
  const { mutateAsync, isLoading } = useMutation(login);
  const { mutateAsync: adminLoginFN, isLoading: isAdminLoading } =
    useMutation(adminLogin);

  const onSubmit = async (data: FormData) => {
    try {
      const FN = isAdmin ? adminLoginFN : mutateAsync;
      const response = await FN(data);
      if (response?.user.isAdmin) setAdmin(response?.user);
      else setUser(response?.user);
      window.localStorage.setItem(TOKEN_KEY, response?.access_token);
      if (response?.user.isAdmin)
        window.localStorage.setItem("userType", "admin");
      setAuthHeader(response?.access_token);
      setToken(response?.access_token);
      if (isAdmin)
        navigate(`${routes.DASHBOARD}/${routes.MEETING_AGENDA.INDEX}`);
      else navigate(routes.DASHBOARD + "/" + routes.MEETINGS.INDEX);
    } catch (error: any) {
      notification.error({
        message: "Login Failed",
        description: error?.errMsg,
      });
    }
  };
  const { locale } = useLocaleContext();
  const { i18n } = useTranslation();

  function changeLocale(l: any) {
    if (locale !== l) {
      i18n.changeLanguage(l);
    }
  }
  React.useEffect(() => {
    const language = window.navigator.language;
    if (languages.en.includes(language)) {
      changeLocale("en");
    } else if (languages.ar.includes(language)) {
      changeLocale("ar");
    } else if (languages.fr.includes(language)) {
      changeLocale("fr");
    }
  }, []);
  return (
    <div className="login-container">
      {contextHolder}
      <Row style={{ width: "100%", height: "100%" }}>
        <Col className="left-panel">
          <img src={logo} alt="Logo" className="logo" />
          <div className="login-form">
            <div className="welcome-message">{t("Welcome")}</div>
            <Form form={form} onFinish={onSubmit}>
              <Form.Item
                name={isAdmin ? "email" : "username"}
                rules={[{ required: true, message: messages.requiredField(t) }]}
              >
                <Input placeholder={t("Email")} prefix={<UserOutlined />} />
              </Form.Item>

              <Form.Item
                name={"password"}
                rules={[{ required: true, message: messages.requiredField(t) }]}
              >
                <Input.Password
                  placeholder={t("Password")}
                  prefix={<LockOutlined />}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  loading={isLoading || isAdminLoading}
                  type="primary"
                  htmlType="submit"
                  block
                >
                  {t("Log in")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          className="right-panel"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
            }}
          >
            <Button
              style={{
                marginLeft: "20px",
              }}
              onClick={() =>
                locale === "en" ? changeLocale("fr") : changeLocale("en")
              }
              shape="round"
            >
              {locale === "en" ? "English" : "Français"}
            </Button>
          </div>
          <Lottie
            animationData={animationData}
            style={{ width: "80%", height: "80%" }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Login;
