import routes from "@/routes";
import { lazy } from "react";
const Department = lazy(() => import("@/modules/Department"));
const ListDepartment = lazy(() => import("@/modules/Department/List"));
const CreateDepartment = lazy(() => import("@/modules/Department/Create"));

const DepartmentRoutes = {
  element: <Department />,
  path: routes.DEPARTMENT.INDEX,
  children: [
    {
      element: <CreateDepartment />,
      path: routes.DEPARTMENT.CREATE,
    },
  ],
};

export default DepartmentRoutes;
