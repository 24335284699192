import * as React from "react";
import i18n from "../i18n";
const localeContext = React.createContext<any>(null);
export function LocaleProvider({ children }: any) {
  const [locale, setLocale] = React.useState(i18n.language);
  i18n.on("languageChanged", (lng: any) => setLocale(i18n.language));
  return (
    <localeContext.Provider value={{ locale, setLocale }}>
      {children}
    </localeContext.Provider>
  );
}
export function useLocaleContext() {
  const context = React.useContext(localeContext);
  if (context === undefined) {
    throw new Error("useLocaleContext can only be used inside LocaleProvider");
  }
  return context;
}
