import PublicLayout from "@/layouts/PublicLayout";
import Login from "@/modules/Login";
import { RouteObject } from "react-router-dom";

export const PublicRoutes: RouteObject[] = [
  {
    element: <PublicLayout />,
    path: "/",
    children: [
      {
        element: <Login isAdmin={false} />,
        path: "/",
      },
      {
        element: <Login isAdmin={true} />,
        path: "/admin",
      },
    ],
  },
];
