import * as React from "react";
import MeetingFollowRoutes from "./dashboard/decision-follow-up";
import DepartmentRoutes from "./dashboard/department.routes";
import DivisionRoutes from "./dashboard/division.routes";
import MeetingAgendaRoutes from "./dashboard/meetingAgenda";
import MeetingRoutes from "./dashboard/meetings.routes";
import OrganizationalUnitRoutes from "./dashboard/organizationalUnits.routes";
import ReportingRoutes from "./dashboard/reportings.route";
import ServiceRoutes from "./dashboard/service.routes";
import SubDepartmentRoutes from "./dashboard/subDepartment.routes";
import AssociationRoutes from "./dashboard/userAssociation.routes";
import UsersRoutes from "./dashboard/users.routes";

const MainLayout = React.lazy(() => import("@/layouts/MainLayout"));

export const MainRoutes = (user: any) => {
  return [
    {
      element: <MainLayout />,
      path: "dashboard",
      children: permittedRoutes(user),
    },
  ];
};
function permittedRoutes(user: any) {
  const routes = [];
  // if (user?.isAdmin) {
  routes.push(MeetingAgendaRoutes);
  routes.push(DepartmentRoutes);
  routes.push(ServiceRoutes);
  routes.push(SubDepartmentRoutes);
  routes.push(UsersRoutes);
  routes.push(DivisionRoutes);
  routes.push(AssociationRoutes);
  routes.push(OrganizationalUnitRoutes);
  routes.push(ReportingRoutes);
  // } else {
  routes.push(MeetingRoutes);
  routes.push(MeetingFollowRoutes);
  // }

  return routes;
}
