import { capitalize } from "lodash";
import { ReactComponent as DisplayLogo } from "@/assets/dashboard/common/display.svg";
import { ReactComponent as LinkedinLogo } from "@/assets/dashboard/common/linkedin.svg";
import { ReactComponent as MetaLogo } from "@/assets/dashboard/common/meta-min.svg";
import { ReactComponent as DV360Logo } from "@/assets/dashboard/common/dv360.svg";
import { ReactComponent as TradeDeskLogo } from "@/assets/dashboard/common/tradedesk.svg";

import routes from "./routes";
import { FavSettingsArrayItem } from "./types";
export const DRAWER_WIDTH = 312;
export const CLOSED_DRAWER_WIDTH = 65;
export const NAV_HEIGHT = 98;
export const DRAWER_HEAD_HEIGHT = 98;
export const FORM_COLUMN_SPACING = 2;
export const FORM_ROW_SPACING = 1.5;
export const LAYOUT_PRIMARY_COLUMN_SPACING = 3;
export const LAYOUT_PRIMARY_ROW_SPACING = 3;
export const LAYOUT_SECONDARY_COLUMN_SPACING = 2;
export const LAYOUT_SECONDARY_ROW_SPACING = 2;
export const FONT_FAMILY = "Poppins, sans-serif";
export const BORDER_RADIUS = "8px";
export const TOKEN_KEY = "token";
export const USER_KEY = "user";
export const PREVIOUS_USER_KEY = "previousUser";
export const BOX_SHADOW = "0px 0px 10px 2px rgba(176, 202, 238, 0.25)";
export const FavSettingsArray: FavSettingsArrayItem[] = [
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    name: "apple-touch-icon",
    href: "/apple-touch-icon.png",
    fileExtension: "png",
  },
  {
    rel: "icon",
    sizes: "32x32",
    href: "/favicon-32x32.png",
    type: "image/png",
    name: "favicon-32x32",
    fileExtension: "png",
  },
  {
    rel: "icon",
    sizes: "16x16",
    href: "/favicon-16x16.png",
    type: "image/png",
    name: "favicon-16x16",
    fileExtension: "png",
  },
  {
    rel: "manifest",
    href: "/site.webmanifest",
    name: "site",
    fileExtension: "webmanifest",
  },
];

export const CHANNELS = {
  DISPLAY: "display",
  LINKEDIN: "linkedin",
  FACEBOOK: "facebook",
} as const;

export type CHANNELS_VALUES = (typeof CHANNELS)[keyof typeof CHANNELS];

export const DESTINATION = {
  DV360: "dv360",
  TRADEDESK: "tradeDesk",
} as const;
export const EXTENDED_CHANNELS = {
  ...CHANNELS,
  ...DESTINATION,
} as const;
export const CRM = {
  SALESFORCE: "salesforce",
  HUBSPOT: "hubSpot",
} as const;

export const EXTENDED_CHANNELS_CRM = {
  ...CHANNELS,
  ...DESTINATION,
  ...CRM,
} as const;
export const CHANNELS_ICONS = {
  LINKEDIN: LinkedinLogo,
  DISPLAY: DisplayLogo,
  DV360: DV360Logo,
  TRADEDESK: TradeDeskLogo,
  FACEBOOK: MetaLogo,
} as const;
export const CHANNELS_LABEL_VALUE = Object.values(CHANNELS).map((item) => ({
  label: capitalize(item),
  value: item,
}));
export const channelListForFilter = [
  { label: "All", value: "all" },
  ...CHANNELS_LABEL_VALUE,
];

export const CHANNELS_TO_CAMPAIGN: Record<CHANNELS_VALUES, string> = {
  display: "BeeswaxCampaign",
  linkedin: "LinkedinCampaign",
  facebook: "FacebookCampaign",
};

// Date Formats
export const DATE_FORMAT_ONE = "YYYY/MM/DD";
export const DATE_FORMAT_TWO = "YYYY-MM-DD";
export const DATE_FORMAT_THREE = "D MMM YYYY hh:mm a";
export const DATE_FORMAT_FOUR = "YYYYMMDD";
export const DATE_FORMAT_FIVE = "YY/MM/DD";

export const USER_TYPE = {
  SUPER_ADMIN: "superAdmin",
  AGENCY_ADMIN: "agencyAdmin",
  AGENCY_USER: "agencyUser",
  ADVERTISER: "advertiserUser",
} as const;
export const USER_TYPE_LABELS = {
  [USER_TYPE.SUPER_ADMIN]: "Super Admin",
  [USER_TYPE.AGENCY_ADMIN]: "Agency Admin",
  [USER_TYPE.AGENCY_USER]: "Agency User",
  [USER_TYPE.ADVERTISER]: "Advertiser User",
};
export const ALLOWED_VIDEO_TYPES = ["mp4"];
export const ALLOWED_IMAGES_TYPES = ["png", "jpeg", "jpg", "gif"];

export const SCOPES = ["R", "RU", "CRUD"] as const;
export const MODULES = {
  DASHBOARD: "DASHBOARD",
  INTEGRATIONS_BEESWAX: "INTEGRATIONS_BEESWAX",
  INTEGRATIONS_FACEBOOK: "INTEGRATIONS_FACEBOOK",
  INTEGRATIONS_LINKEDIN: "INTEGRATIONS_LINKEDIN",
  INTEGRATIONS_SALESFORCE: "INTEGRATIONS_SALESFORCE",
  INTEGRATIONS_HUBSPOT: "INTEGRATIONS_HUBSPOT",
  INTEGRATIONS_DISPLAYVIDEO360: "INTEGRATIONS_DISPLAYVIDEO360",
  INTEGRATIONS_TRADEDESK: "INTEGRATIONS_TRADEDESK",
  ADVERTISERS: "ADVERTISERS",
  ADVERTISERS_INTEGRATIONS_LINKEDIN: "ADVERTISERS_INTEGRATIONS_LINKEDIN",
  ADVERTISERS_INTEGRATIONS_SALESFORCE: "ADVERTISERS_INTEGRATIONS_SALESFORCE",
  ADVERTISERS_INTEGRATIONS_HUBSPOT: "ADVERTISERS_INTEGRATIONS_HUBSPOT",
  ADVERTISERS_INTEGRATIONS_DISPLAYVIDEO360:
    "ADVERTISERS_INTEGRATIONS_DISPLAYVIDEO360",
  ADVERTISERS_INTEGRATIONS_TRADEDESK: "ADVERTISERS_INTEGRATIONS_TRADEDESK",
  CAMPAIGNS: "CAMPAIGNS",
  AUDIENCE_ACCOUNT: "AUDIENCE_ACCOUNT",
  AUDIENCE_SITE_VISITOR: "AUDIENCE_SITE_VISITOR",
  AUDIENCE_EMAIL: "AUDIENCE_EMAIL",
  AUDIENCE_MANAGER: "AUDIENCE_MANAGER",
  ADVERTISER_USERS: "ADVERTISER_USERS",
  AGENCY_USERS: "AGENCY_USERS",
  ATTRIBUTION: "ATTRIBUTION",
  CREATIVES: "CREATIVES",
  FORMS: "FORMS",
  LEAD_APPEND: "LEAD_APPEND",
  UTMS: "UTMS",
  TAGS: "TAGS",
  CONVERSIONS: "CONVERSIONS",
  VISITOR_INSIGHTS: "VISITOR_INSIGHTS",
  CONTACT_BI: "CONTACT_BI",
  CAMPAIGN_STATS: "CAMPAIGN_STATS",
  CAMPAIGN_STATS_COMPLETE: "CAMPAIGN_STATS_COMPLETE",
  ABM_STATS: "ABM_STATS",
  REPORTS_SETUP: "REPORTS_SETUP",
  TARGETING_EXPRESSION_PRESET: "TARGETING_EXPRESSION_PRESET",
} as const;

export const EXTENDED_CHANNELS_AGENCY_PERMISSIONS = {
  [EXTENDED_CHANNELS.DISPLAY]: MODULES.INTEGRATIONS_BEESWAX,
  [EXTENDED_CHANNELS.LINKEDIN]: MODULES.INTEGRATIONS_LINKEDIN,
  [EXTENDED_CHANNELS.FACEBOOK]: MODULES.INTEGRATIONS_FACEBOOK,
} as const;

export const EXTENDED_CHANNELS_CRM_ADVERTISER_PERMISSIONS = {
  [EXTENDED_CHANNELS.LINKEDIN]: MODULES.INTEGRATIONS_LINKEDIN,
  [EXTENDED_CHANNELS.DV360]: MODULES.INTEGRATIONS_DISPLAYVIDEO360,
  [EXTENDED_CHANNELS.TRADEDESK]: MODULES.INTEGRATIONS_TRADEDESK,
  [CRM.HUBSPOT]: MODULES.INTEGRATIONS_HUBSPOT,
  [CRM.SALESFORCE]: MODULES.INTEGRATIONS_SALESFORCE,
};

export const BREADCRUMS_MAP = {
  [routes.MEETINGS.INDEX]: "Meetings",
} as const;

export const DEMO_COMPANY_ID = 238;
export const userRole = {
  MANAGER: "MANAGER",
  ASSOCIATE: "ASSOCIATE",
};

export const DEFAULT_PAGE_SIZE = 10;
export const languages = {
  ar: [
    "ar",
    "ar-SA",
    "ar-DZ",
    "ar-EG",
    "ar-IQ",
    "ar-JO",
    "ar-KW",
    "ar-LB",
    "ar-LY",
    "ar-MA",
    "ar-OM",
    "ar-QA",
    "ar-SA",
    "ar-SD",
    "ar-SY",
    "ar-TN",
    "ar-AE",
    "ar-YE",
  ],
  en: [
    "en-US",
    "en",
    "en-EG",
    "en-AU",
    "en-GB",
    "en-CA",
    "en-NZ",
    "en-IE",
    "en-ZA",
    "en-JM",
    "en-BZ",
    "en-TT",
  ],
  fr: ["fr", "fr-BE", "fr-CA", "fr-CH", "fr-LU"],
};
