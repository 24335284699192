import routes from "@/routes";
import { lazy } from "react";
const Insight = lazy(() => import("@/modules/Meetings"));
const ListInsight = lazy(() => import("@/modules/Meetings/List"));
const CreateMeetings = lazy(() => import("@/modules/Meetings/Create"));

const MeetingRoutes = {
  element: <Insight />,
  path: routes.MEETINGS.INDEX,
  children: [
    {
      element: <ListInsight />,
      path: "",
    },
    {
      element: <ListInsight />,
      path: routes.MEETINGS.MANAGER_MEETING,
    },
    {
      element: <ListInsight />,
      path: routes.MEETINGS.ASSOCIATE_MEETING,
    },
    {
      element: <ListInsight />,
      path: routes.MEETINGS.CONFIRMED_MEETING,
    },
    {
      element: <ListInsight />,
      path: routes.MEETINGS.COMPLETED_MEETING,
    },
    {
      element: <CreateMeetings />,
      path: routes.MEETINGS.CREATE,
    },
    {
      element: <CreateMeetings isEdit={false} isView={true} />,
      path: routes.MEETINGS.VIEW(),
    },
    {
      element: <CreateMeetings isEdit={true} isView={false} />,
      path: routes.MEETINGS.EDIT(),
    },
  ],
};

export default MeetingRoutes;
