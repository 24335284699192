import routes from "@/routes";
import { lazy } from "react";
const OrganizationalUnit = lazy(() => import("@/modules/OrganizationalUnit"));
const ListOrganizationalUnit = lazy(
  () => import("@/modules/OrganizationalUnit/List")
);

const OrganizationalUnitRoutes = {
  element: <OrganizationalUnit />,
  path: routes.ORGANIZATION_UNIT.INDEX,
  children: [
    {
      element: <ListOrganizationalUnit />,
      path: "",
    },
  ],
};

export default OrganizationalUnitRoutes;
