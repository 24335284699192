import routes from "@/routes";
import { lazy } from "react";
const Reportings = lazy(() => import("@/modules/Reporting"));
const ListReportings = lazy(() => import("@/modules/Reporting/List"));

const ReportingRoutes = {
  element: <Reportings />,
  path: routes.REPORTINGS.INDEX,
  children: [
    {
      element: <ListReportings />,
      path: "",
    },
  ],
};

export default ReportingRoutes;
