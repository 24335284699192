import appendQueryParams from "@/utils/appendQueryParams";
import { QueryFunctionContext } from "@tanstack/react-query";
import client from "../client";

export function getUserDetails() {
  return client.get("auth/profile");
}
export function createUser(payload: any) {
  return client.post("users", payload);
}
export function findAllUsers() {
  return client.get("users");
}
export function findOnlyAssigned() {
  return client.get("users/assigned");
}
export function associateEmployee(payload: any) {
  return client.post("users/associate-employee", payload);
}
export const fetchHosts = async () => {
  return await client.get("/users/managers");
};
export const fetchNotReadyToUse = async () => {
  return await client.get("/users/not-assigned");
};

export const uploadProfilePicture = async (payload: any) => {
  return await client.post("/users/upload-profile-picture", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
