import CustomLoader from "@/components/Loader/CustomLoader";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale/en_US";
import frFR from "antd/lib/locale/fr_FR";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { useUserContext } from "./context/UserContext";
import { MainRoutes } from "./routes/main.routes";
import { PublicRoutes } from "./routes/public.routes";
export default function Routes() {
  const { user } = useUserContext();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      children: [...MainRoutes(user), ...PublicRoutes],
    },
  ]);

  return <RouterProvider router={router} fallbackElement={<CustomLoader />} />;
}
function App() {
  const { i18n } = useTranslation();
  return (
    <ConfigProvider locale={i18n.language === "fr" ? frFR : enUS}>
      <div
        className="App"
        style={{ width: "100%", height: "100%", minHeight: "100vh" }}
      >
        <Suspense fallback={<CustomLoader />}>
          <Outlet />
        </Suspense>
      </div>
    </ConfigProvider>
  );
}
