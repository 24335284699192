import { getMeetingPendingCount } from "@/apis/v1/meetings";
import * as React from "react";
import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useUserContext } from "./UserContext";
const MeetingCountContext = React.createContext<any>(null);

export const MeetingCountProvider = ({ children }: any) => {
  const { user } = useUserContext();
  const [count, setCount] = useState(0);
  console.log({ count });

  const fetchData = async () => {
    const count = await getMeetingPendingCount();
    setCount(count);
  };
  useEffect(() => {
    if (user) {
      fetchData();
    }
    const socket = io(import.meta.env.VITE_API_BASE_URL as string, {
      transports: ["websocket"], // Use WebSocket only
    });
    socket.on("connect", () => {
      if (user?.id) socket.emit("joinRoom", user?.id);
    });

    socket.on("meetingCountUpdate", async () => {
      fetchData();
    });

    return () => {
      socket.disconnect();
    };
  }, [user]);

  return (
    <MeetingCountContext.Provider value={{ count, setCount, fetchData }}>
      {children}
    </MeetingCountContext.Provider>
  );
};

export function useMeetingCount() {
  const context = React.useContext(MeetingCountContext);
  if (!context) {
    throw new Error("useMeetingCount can only be used inside UserProvider");
  }
  return context;
}
