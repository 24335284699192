import routes from "@/routes";
import { lazy } from "react";
const Division = lazy(() => import("@/modules/Division"));
const ListDivision = lazy(() => import("@/modules/Division/List"));
const CreateDivision = lazy(() => import("@/modules/Division/Create"));

const DivisionRoutes = {
  element: <Division />,
  path: routes.DIVISION.INDEX,
  children: [
    {
      element: <CreateDivision />,
      path: routes.DIVISION.CREATE,
    },
  ],
};

export default DivisionRoutes;
